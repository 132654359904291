/*
 * 업무구분: 영업
 * 화 면 명: MSPFS311P
 * 화면설명: 부지점_P
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
    <!-- popup -->
    <mo-modal class="fts-modal medium" ref="modal" title="부지점">
      <template>
        <div class="wrap-modalcontents">
          <div class="input-top">
            <div class="left">
              <div class="wrap-input row">
                <label> 부점코드 </label>
                <mo-text-field v-model="bfcer_fof_cd" clearable placeholder="입력하세요" class="w100" type="number" maxlength="3" />
              </div>
              <div class="wrap-input row">
                <label> 부점명 </label>
                <mo-text-field v-model="bfcer_fof_nm" clearable placeholder="입력하세요" />
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button primary class="inquiry" @click="fn_search"> 조회 </mo-button>
              </div>
            </div>
          </div>
          <div class="wrap-table mt-3 h-scroll">
            <table class="table row-type">
              <thead>
                <tr>
                  <th scope="col" style="width:15%">선택</th>
                  <th scope="col" style="width:25%">부점코드</th>
                  <th scope="col">부점명</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in ds_list" :key="i" @click="fn_clickRow(i, row.bfcer_fof_cd)" >
                  <td><mo-radio v-model="sel_bfcer_fof_cd" :value="row.bfcer_fof_cd" @click="fn_clickRow(i, row.bfcer_fof_cd)" /></td>
                  <td>{{row.bfcer_fof_cd}}</td>
                  <td class="text-left">{{row.bfcer_fof_nm}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close">닫기</mo-button>
          <mo-button primary size="large" @click="fn_confirm">확인</mo-button>
        </div>
      </template>
    </mo-modal>
    
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS311P",
    screenId: "MSPFS311P",
    components: {},
    props: {
      popupObj: {type:Object, default:null},
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog('MSPFS311P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        bfcer_fof_cd : '', //부점코드
        bfcer_fof_nm : '', //부점명
        ds_list : [],
        sel_bfcer_fof_cd : '', // 선택된 row의 부점 코드
        selIndex : '', // 선택된 row
        pAlertPopupObj: {}, //alert 
        eaiCommObj: FSCommUtil.gfn_eaiCommObj() 
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // modal
      modal() {return this.$refs.modal},
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /***********************************************************************************
      * Function명 : fn_init
      * 설명       : 초기화
      ***********************************************************************************/
      fn_init(){
        //부점코드/부점명 초기화 
        this.bfcer_fof_cd = ''
        this.bfcer_fof_nm = ''
        //result 초기화 
        this.ds_list = []
      },
      /***********************************************************************************
      * Function명 : fn_search 
      * 설명       : 조회 버튼 클릭 이벤트
      ***********************************************************************************/
      fn_search(){
        this.sel_bfcer_fof_cd = ''
        this.selIndex = ''

        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS91S5'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570271_S'
        this.eaiCommObj.params = {
          bfcer_fof_cd : this.bfcer_fof_cd,
          bfcer_fof_nm : this.bfcer_fof_nm
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
      },
      /**********************************************************************************
       * Function명 : fn_callBack
       * 설명       : fn_search 콜백
      **********************************************************************************/
      fn_callBack(response){
        console.log('부지점response', response);
        var data = response.data.sy000400_O_00VO

        if(data.length > 0){
          this.ds_list = data
        }else{
          let t_popupObj = {}
          t_popupObj.confirm = false
          t_popupObj.content = "부점이 존재하지 않습니다."
          this.fn_AlertPopup(t_popupObj)
        }
        
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (pData) {
         this.$emit('fs-alert-popup', 0, pData)
      },
      /**********************************************************************************
       * Function명 : fn_clickYes
       * 설명       : 확인 클릭
      **********************************************************************************/
      fn_clickYes(index){
        var objRtn = this.ds_list[index];
        this.fn_Close(objRtn);
      },
      /***********************************************************************************
      * Function명 : fn_clickRow
      * 설명       : row클릭 이벤트
      ***********************************************************************************/
      fn_clickRow(index, bfcer_fof_cd) {
        this.selIndex = index
        this.sel_bfcer_fof_cd = bfcer_fof_cd
      },
      /***********************************************************************************
      * Function명 : fn_Open
      * 설명       : 팝업 open
      ***********************************************************************************/
      fn_Open() {
        this.sel_bfcer_fof_cd = ''
        this.selIndex = ''
        
        this.modal.open()
      },
      /***********************************************************************************
      * Function명 : fn_close
      * 설명       : 팝업 close
      ***********************************************************************************/
      fn_Close(objRtn) {
        if(objRtn.bfcer_fof_cd == ''){
          //닫기 버튼 클릭 시 
          objRtn = {};
        }
        this.$emit('fs-popup-callback', 0, objRtn)
        this.fn_init();
        this.modal.close()
      },
      /***********************************************************************************
      * Function명 : fn_confirm
      * 설명       : 확인버튼 클릭
      ***********************************************************************************/
      fn_confirm() {
        if(this.sel_bfcer_fof_cd == '') {
          let t_popupObj = {}
          t_popupObj.confirm = false
          t_popupObj.content = "선택된 부점이 없습니다."
          this.fn_AlertPopup(t_popupObj)
        } else {
          var objRtn = this.ds_list[this.selIndex];
          this.fn_Close(objRtn);
        }
      }
    }//methods end
  }
</script>
<style scoped>
</style>